body,
html {
  background: #fafafa;
  color: #333;
}

h1 {
  margin-top: 4em;
  padding-left: 1.5rem;
}

.papers {
  margin: auto;
  margin-top: 3em;
  min-width: 72em;
  margin-bottom: 10em;
}

.papers td {
  text-align: left;
}

#root {
  display: flex;
  justify-content: center;
}

.head {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  max-width: 1200px;
}

td {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

td:first-child {
  padding-left: 1.5rem;
}

td:last {
  padding-right: 1.5rem;
}

tr > td:nth-child(3) {
  text-align: center;
}

tr > td:nth-child(4) {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.edit {
  all: unset;
  margin-top: 0.5em;
  margin-left: 0.5em;
  opacity: 0;
  font-size: 70%;
  cursor: pointer !important;
  margin-left: 2em;
}
.edit:hover {
  color: blue;
}

tr:hover .edit {
  opacity: 0.5;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

input {
  line-height: 1.5;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font: inherit;
}

.label {
  font-weight: bold;
  margin-right: 0.5em;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.text-xl {
  font-size: 120%;
}

.justify-center {
  justify-content: center;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
}

label span {
  display: inline-block;
  width: 7em;
}

.add-document {
  padding: 2em;
  margin-bottom: 5em;
}

.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}

input[type="text"] {
  width: 80%;
  border: 1px solid #333;
}

.subheader {
  padding-top: 6em;
  padding-bottom: 4em;
}

.subheader header {
  font-size: 150%;
  max-width: 800px;
}

h1 {
  max-width: 800px;
  line-height: 1.7;
}

h1 input {
  margin-top: 0.5em;
}

.no-journal {
  background: rgb(250, 200, 200);
}

.no-wrap {
  white-space: nowrap;
}

.papers td.text-center {
  text-align: center;
}
